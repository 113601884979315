<template>
    <div>
        <el-row>
            <el-col :span="24">
                <el-form :inline="true">
                    <el-form-item label="关键字:">
                        <el-input
                            v-model="filters.keyWord"
                            placeholder="请输入姓名/手机号"
                            clearable
                        >
                        </el-input>
                    </el-form-item>
                    <el-form-item label='单位信息:'>
                        <el-input v-model='filters.unitName' placeholder='请输入单位信息' clearable></el-input>
                    </el-form-item>
                    <el-form-item label='培训机构:' prop='stydyOrganization'>
                        <el-select v-model="filters.trainingInstitutions" filterable remote :remote-method="searchOrgFun" placeholder="请搜索/选择" clearable class="social">
                            <el-option
                                v-for="item in stydyOrganizationArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <!-- <el-form-item label='批次名称:'>
                        <el-select v-model="filters.selectBatch" placeholder="请选择" clearable>
                            <el-option
                                v-for="item in bathArr"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID"
                            >
                            </el-option>
                            <el-pagination
                                layout="prev, pager, next"
                                @current-change="addBathArrChange"
                                :current-page="this.addBathIndex"
                                :total="bathArr.length">
                            </el-pagination>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item label='缴费状态:'>
                        <el-select v-model="filters.isPay" placeholder="请选择" clearable>
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label='是否启用:'>
                        <el-select v-model="filters.isEnable" placeholder="请选择">
                            <el-option
                                v-for="item in isEnableArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
<!--                    <el-form-item label='数据来源:'>-->
<!--                        <el-select v-model="filters.dataSource" placeholder="请选择" clearable>-->
<!--                            <el-option-->
<!--                                v-for="item in dataSourceArr"-->
<!--                                :key="item.value"-->
<!--                                :label="item.label"-->
<!--                                :value="item.value"-->
<!--                            >-->
<!--                            </el-option>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
                    <!-- <el-form-item label='标签:'>
                        <el-select v-model="filters.labelTy" placeholder="请选择" clearable>
                            <el-option
                                v-for="item in labelTypeArr"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label='是否免培考试:'>
                        <el-select v-model="filters.IsAllowExamination" placeholder="请选择" clearable>
                            <el-option
                            v-for="item in IsAllowExaminationArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
	                <el-form-item label='岗位名称:'>
		                <el-select v-model="filters.stationName" placeholder="请选择" clearable>
			                <el-option
					                v-for="item in stationName"
					                :key="item.Id"
					                :label="item.Name"
					                :value="item.Id"
			                >
			                </el-option>
		                </el-select>
	                </el-form-item>
	                <el-form-item label='行业名称:'>
		                <el-select v-model="filters.trateName" placeholder="请选择" clearable>
			                <el-option
					                v-for="item in trateName"
					                :key="item.Id"
					                :label="item.Name"
					                :value="item.Id"
			                >
			                </el-option>
		                </el-select>
	                </el-form-item>
                    <el-form-item>
                        <toolbar
                            :buttonList="buttonList"
                            @callFunction="callFunction"
                        ></toolbar>
                    </el-form-item>
                </el-form>
                </el-col>
        </el-row>
        <!-- 表格主体 -->
        <el-table
            border
            highlight-current-row
            :data="tableData"
            v-loading="listLoading"
            :cell-style='rowClass'
            :header-cell-style='headerClass'
            ref="multipleTable"
            @selection-change="handleSelectChange"
            style="width: 100%"
        >
            <!-- <el-table-column type='index' label='序号' width='60'></el-table-column> -->
            <el-table-column type="index" width="50" align="center"></el-table-column>
            <el-table-column prop="Name" label="姓名" width='80'></el-table-column>
            <el-table-column prop="PhoneNumber" label="短信验证手机号" min-width="140"></el-table-column>
            <el-table-column prop="PositionName" label="职务" min-width="100"></el-table-column>
            <!-- <el-table-column prop="ExamProjectName" label="项目名称"></el-table-column> -->
            <el-table-column prop='TradeName' label='行业名称' min-width="100"></el-table-column>
            <!-- <el-table-column prop='TradeNotes' label='行业简介' show-overflow-tooltip></el-table-column> -->
            <el-table-column prop='StationName' label='岗位名称' min-width="100"></el-table-column>
            <el-table-column prop='UnitName' label='所在单位' min-width="100"></el-table-column>
            <el-table-column prop='AgencyInfoName' label='培训机构' min-width="100"></el-table-column>
            <!-- <el-table-column prop='StationNotes' label='岗位简介' show-overflow-tooltip></el-table-column> -->
            <!-- <el-table-column prop="IsPay" label="是否缴费">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsPay == 0' type='primary'>无需缴费</el-tag>
                    <el-tag v-if='scope.row.IsPay == 1' type='success'>已缴费</el-tag>
                    <el-tag v-if='scope.row.IsPay == 2' type='danger'>未缴费</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="PayAmount" label="需缴费金额"></el-table-column>
            <el-table-column prop="PayTime" label="缴费时间"></el-table-column>
            <el-table-column prop="PayWay" label="缴费方式">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.PayWay == 0'>支付宝</el-tag>
                    <el-tag v-if='scope.row.PayWay == 1'>微信</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="PayOrderNo" label="缴费单号"></el-table-column> -->
            <el-table-column prop="IsExemptTrain" label="是否免培考试" fixed="right" min-width="120">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsExemptTrain' type='success'>是</el-tag>
                    <el-tag v-else type='warning'>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="IsPass" label="是否取证" fixed="right" min-width="100">
                <template slot-scope='scope'>
                    <el-tag v-if='scope.row.IsPass' type='success'>是</el-tag>
                    <el-tag v-else type='warning'>否</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop='IsVerification' label='是否验证' fixed="right" min-width="100">
                <template slot-scope='scope'>
                    <el-tag :type="scope.row.IsVerification == true ? 'success' : 'warning' ">{{scope.row.IsVerification == true ? '是' : '否'}}</el-tag>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="IsEnable" label='启用标识' fixed="right">
                <template slot-scope='scope'>
                    <el-tag :type='scope.row.IsEnable== true ? "success" : "warning"'>{{scope.row.IsEnable == true ? '启用' : '禁用'}}</el-tag>
                </template>
            </el-table-column> -->
<!--            <el-table-column prop="" label="学习记录" fixed="right" min-width="100">-->
<!--                <template scope="scope">-->
<!--                    <el-button type="text" @click="viewStudentMsgDetail(scope.row)">查看</el-button>-->
<!--                </template>-->
<!--            </el-table-column>-->
        </el-table>
        <!-- 分页 -->
        <el-col :span="24" class="pageBarContainer">
            <div>已选择<span class="selectNumCla">{{allSelect.length}}</span>条</div>
            <div>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="this.pages.pageIndex"
                    :page-sizes="[10, 20, 40, 100]"
                    :page-size="this.pages.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="this.pages.dataCount"
                    class='pageBar'
                >
                </el-pagination>
            </div>
        </el-col>
        <!-- 新增 -->
        <el-dialog
            :title="isAddOrEdit?'新增':'编辑'"
            :visible.sync="addFormDialog"
            v-model="addFormDialog"
            @close="dialogCloseFun"
            :close-on-click-modal="false"
        >
            <el-form :model="dialogForm" :rules="dialogFormRules" ref="dialogFormRef" label-width="150px" enctype="multipart/form-data">
                <el-form-item label='姓名:' prop='name'>
                    <el-input v-model='dialogForm.name' clearable></el-input>
                </el-form-item>
                <el-form-item label='手机号:' prop='phone'>
                    <el-input v-model='dialogForm.phone' clearable></el-input>
                </el-form-item>
                <el-form-item label='身份证号:' prop='userCard'>
                    <el-input v-model='dialogForm.userCard' @blur="onBlur(dialogForm.userCard)" clearable></el-input>
                </el-form-item>
                <el-form-item label='性别:'>
                    <el-select v-model="dialogForm.userSex" :disabled="true" placeholder="请选择" clearable>
                        <el-option
                            v-for="item in sexArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label='单位名称:' prop='unitName'>
                    <el-input v-model='dialogForm.unitName' clearable></el-input>
                </el-form-item> -->
                <el-form-item label='单位名称:' prop='unitName'>
                    <el-select v-model="dialogForm.unitName" filterable remote :remote-method="searchFun" placeholder="请搜索/选择" clearable class="social">
                        <el-option
                            v-for="item in SocialUnitList"
                            :key="item.ID"
                            :label="item.UnitName"
                            :value="item.ID">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='通讯地址:' prop='unitAddress'>
                    {{dialogForm.unitAddress}}
                    <!-- <el-input v-model='dialogForm.unitAddress' clearable></el-input> -->
                </el-form-item>
                <el-form-item label='培训机构:' prop='stydyOrganization' v-if="isShowOrg">
                    <el-select v-model="dialogForm.stydyOrganization" filterable remote :remote-method="searchOrgFun" placeholder="请搜索/选择" clearable class="social">
                        <el-option
                            v-for="item in stydyOrganizationArr"
                            :key="item.ID"
                            :label="item.Name"
                            :value="item.ID"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='岗位分类:' prop='postType'>
                    <el-select v-model="dialogForm.postType" placeholder="请选择岗位分类" clearable>
                        <el-option
                            v-for="item in postTypeArr"
                            :key="item.Code"
                            :label="item.Name"
                            :value="item.Code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='行业分类:'>
                    <el-select v-model="dialogForm.industryType" :disabled="isTradeSelect" :placeholder="tradePlaceholder" clearable>
                        <el-option
                            v-for="item in industryTypeArr"
                            :key="item.Code"
                            :label="item.Name"
                            :value="item.Code">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label='证件照:'>
                    <el-upload
                    :action="uploadImagesUrl"
                    list-type="picture-card"
                    :before-upload="beforeUpload"
                    :on-success="handle_success"
                    :headers="headers"
                    ref="uploadRef"
                    :auto-upload="true">
                        <i slot="default" class="el-icon-plus"></i>
                        <div slot="file" slot-scope="{file}">
                        <img
                            class="el-upload-list__item-thumbnail"
                            :src="file.url" alt=""
                        >
                        <span class="el-upload-list__item-actions">
                            <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemove(file)"
                            >
                            <i class="el-icon-delete"></i>
                            </span>
                        </span>
                        </div>
                    </el-upload>
                </el-form-item>
                <el-form-item label='标签:'>
                    <el-select v-model="filters.labelType" placeholder="请选择" clearable>
                        <el-option
                            v-for="item in labelTypeArr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addFormDialog = false">取消</el-button>
                <el-button type="primary" @click.native="addSubmit" :loading="addLoading">保存</el-button>
            </div>
        </el-dialog>
        <!-- 导入对话框 -->
        <el-dialog
            title="导入"
            :visible.sync="importDialogVisible"
            :close-on-click-modal="true"
            width="500px"
        >
            <el-upload
                drag
                :limit='1'
                ref="upload"
                :on-error="submitFileError"
                :on-success="submitFileSuccess"
                :before-upload="beforeUploadFile"
                :on-exceed="submitFileMore"
                :show-file-list="false"
                :action="actions"
                :headers='headers'
                :multiple='false'
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传文件，且不超过2MB</div>
            </el-upload>
            <span slot="footer" class="dialog-footer">
                <el-button @click="importDialogVisible = false">取 消</el-button>
            </span>
        </el-dialog>
        <!-- 生成批次 -->
        <el-dialog
            title="生成批次"
            :visible.sync="generateBatchDialog"
            v-model="generateBatchDialog"
            :close-on-click-modal="false"
        >
            <el-form :model="generateBatchForm" :rules="generateBatchFormRules" ref="generateBatchFormRef" label-width="150px" enctype="multipart/form-data">
                <el-form-item label='批次名称:' prop='batchName'>
                    <el-input v-model='generateBatchForm.batchName' clearable></el-input>
                </el-form-item>
                <div style="text-align: right;font-size: 16px;">批次人数：{{selectionData.length}}人</div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="generateBatchDialog = false">取消</el-button>
                <el-button type="primary" @click.native="generateBatchSubmit" :loading="generateBatchLoading">保存</el-button>
            </div>
        </el-dialog>
        <!-- 加入批次 -->
        <el-dialog
            title="加入批次"
            :visible.sync="addBatchDialog"
            v-model="addBatchDialog"
            :close-on-click-modal="false"
        >
            <div style="line-height:38px;" v-for="(item,index) in bathArr" :key="index">
                <el-radio v-model="selectBatchRadio" :label="item.ID">{{item.Name}}</el-radio>
            </div>
            <el-pagination
                layout="prev, pager, next"
                @current-change="addBathArrChange"
                :current-page="this.addBathIndex"
                :total="bathArr.length">
            </el-pagination>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addBatchDialog = false">取消</el-button>
                <el-button type="primary" @click.native="addBatchSubmit" :loading="addBatchLoading">保存</el-button>
            </div>
        </el-dialog>
        <!-- 申请组织考试 -->
        <el-dialog
            title="填写考试时间和地点"
            :visible.sync="sendMessageDialog"
            v-model="sendMessageDialog"
            :close-on-click-modal="false"
        >
            <el-form :model="sendMessageForm" :rules="sendMessageFormRules" ref="sendMessageFormRef" label-width="150px" enctype="multipart/form-data">
                <el-form-item label='设置考试时间:' prop='examTimer'>
                    <el-date-picker
                        v-model="sendMessageForm.examTimer"
                        type="datetime"
                        placeholder="选择日期时间"
                        style="width: 60%;"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label='设置考试地点:' prop='examAddress'>
                    <el-input style="width: 60%;" placeholder="请输入考试地点" v-model='sendMessageForm.examAddress' clearable></el-input>
                </el-form-item>
            </el-form>
            <div style="width: 90%;margin: 0 auto;">
                <div class="messageTitle">
                    短信模板内容
                </div>
                <div class="messageContent">
                    您好，您在消防考试宝上申请的免培考试已通过，请于<span v-if="DateTime == 'DateTime'">${ {{DateTime}} }</span><span v-if="DateTime != 'DateTime'"> {{DateTime}} </span>前往<span v-if="Address == 'Address'">${ {{Address}} }</span><span v-if="Address != 'Address'"> {{Address}} </span>参加集中考试！
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="sendMessageDialog = false">取消</el-button>
                <el-button type="primary" @click.native="sendMessageSubmit" :loading="sendMessageLoading">提交申请</el-button>
            </div>
        </el-dialog>
        <!-- 学习记录 -->
        <el-dialog
            title="学习记录"
            :visible.sync="examRecodeDialog"
            v-model="examRecodeDialog"
            width="70%"
            :close-on-click-modal="true"
        >
            合格课时：{{studyTimer}}&nbsp;&nbsp;&nbsp;&nbsp;学习课时：{{studyTimerlong}}
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="视频学习记录" name="first">
                    <el-table
                        border
                        highlight-current-row
                        :data="studyTableData"
                        v-loading="studyTableLoading"
                        :cell-style='rowClass'
                        :header-cell-style='headerClass'
                        ref="studyMultipleTable"
                        style="width: 100%"
                    >
                        <el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
                        <el-table-column prop="StudyTime" label="学习日期"></el-table-column>
                        <el-table-column prop="VideoName" label="学习视频"></el-table-column>
                        <el-table-column prop="DurationStr" label="学习时长">
                            <template scope="scope">
                                <!-- {{second(scope.row.OnlineDuration)}} -->
                                {{scope.row.DurationStr}}
                            </template>
                        </el-table-column>
                        <el-table-column prop='ClockInPics' label='自拍数量'>
                            <template scope="scope">
                                <span v-if="scope.row.ClockInPics.length < 1">0</span>
                                <el-button type="text" v-else @click="viewStudyPhoto(scope.row)">{{scope.row.ClockInPics.length}}</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        @size-change="studySizeChange"
                        @current-change="studyCurrentChange"
                        :current-page="this.studyRecode.studyIndex"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="this.studyRecode.studySize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="this.studyRecode.studyCount"
                        class='pageBar'
                    >
                    </el-pagination>
                </el-tab-pane>
                <el-tab-pane label="试题练习记录" name="second">
                    <el-table
                        border
                        highlight-current-row
                        :data="practiseTableData"
                        v-loading="practiseTableLoading"
                        :cell-style='rowClass'
                        :header-cell-style='headerClass'
                        ref="practiseMultipleTable"
                        style="width: 100%"
                    >
                        <el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
                        <el-table-column prop="TrainTime" label="学习日期"></el-table-column>
                        <el-table-column prop="TrainCount" label="练习题数"></el-table-column>
                        <el-table-column prop="TrainDurationStr" label="学习时长"></el-table-column>
                    </el-table>
                    <el-pagination
                        @size-change="practiseSizeChange"
                        @current-change="practiseCurrentChange"
                        :current-page="this.studyRecode.practiseIndex"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="this.studyRecode.practiseSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="this.studyRecode.practiseCount"
                        class='pageBar'
                    >
                    </el-pagination>
                </el-tab-pane>
                <el-tab-pane label="模拟考试记录" name="third">
                    <el-table
                        border
                        highlight-current-row
                        :data="examTableData"
                        v-loading="examTableLoading"
                        :cell-style='rowClass'
                        :header-cell-style='headerClass'
                        ref="examMultipleTable"
                        style="width: 100%"
                    >
                        <el-table-column type="index" label="序号" width="70" align="center"></el-table-column>
                        <el-table-column prop="ExamTime" label="考试日期"></el-table-column>
                        <el-table-column prop="PaperName" label="试卷名称"></el-table-column>
                        <el-table-column prop="ExamDurationStr" label="考试用时">
                            <template scope="scope">
                                <!-- {{second(scope.row.ExamDuration)}} -->
                                {{scope.row.ExamDurationStr}}
                            </template>
                        </el-table-column>
                        <el-table-column prop='TotalScore' label='得分'></el-table-column>
                        <el-table-column prop='IsQualified' label='是否及格'>
                            <template scope="scope">
                                <el-tag v-if="scope.row.IsQualified == 1" type="success">及格</el-tag>
                                <el-tag v-else type="danger">不及格</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop='QuestionCount' label='答题数'></el-table-column>
                        <el-table-column prop='PaperDurationStr' label='学习时长'>
                            <template scope="scope">
                                <!-- {{second(scope.row.PaperDuration)}} -->
                                {{scope.row.PaperDurationStr}}
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                        @size-change="examSizeChange"
                        @current-change="examCurrentChange"
                        :current-page="this.studyRecode.examIndex"
                        :page-sizes="[10, 20, 30, 40]"
                        :page-size="this.studyRecode.examSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="this.studyRecode.examCount"
                        class='pageBar'
                    >
                    </el-pagination>
                </el-tab-pane>
            </el-tabs>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="examRecodeDialog = false">取消</el-button>
                <!-- <el-button type="primary" @click.native="sendMessageSubmit" :loading="sendMessageLoading">提交</el-button> -->
            </div>
        </el-dialog>
        <!-- 打卡记录 -->
        <el-dialog
            title="打卡记录"
            :visible.sync="clockPhotoDialog"
            v-model="clockPhotoDialog"
            :close-on-click-modal="false"
        >
            <el-table
                border
                highlight-current-row
                :data="clockPhotoData"
                v-loading="clockPhotoLoading"
                :cell-style='rowClass'
                :header-cell-style='headerClass'
                ref="clockPhotoMultipleTable"
                style="width: 100%"
            >
                <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                <el-table-column prop="Time" label="打卡时间"></el-table-column>
                <el-table-column prop="PicUrl" label="照片">
                    <template slot-scope='scope'>
                        <el-popover placement='right' trigger='hover'>
                            <img :src="scope.row.PicUrl" alt="" style='width:120px;height:150px;text-align:center'>
                            <img slot="reference" :src="scope.row.PicUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;text-align:center'>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="clockPhotoDialog = false">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Toolbar from "../../components/Toolbar";
import Qs from "qs";
import { getButtonList } from "../../promissionRouter";
import { getStudentExamEnrollListPage,getExamEnrollInfoListPage,getExamEnrollInfoListPageNew,submitStudentInformationByManger,importStudentExamEnrollInfoUrl,exportExamEnrollInfo,getDataDictionaryList,getSocialUnitListPage,generateBatch,getExamBatchPopulationList,addBatch,updateIsExemptTrain,candidateSendsSMS,getStudentExamineeAccountExamListPage,getStudentExamineeAccountStudyListPage,getStudentExamineeAccountTrainListPage,getLearnRecordByEnroll,getAgencyInfoListPage } from '@/api/api'
import { validPhone,valididentityCard } from "../../../util/validate";
import util from "../../../util/date";
import api from '@/api'
export default {
    components: { Toolbar },
    data(){
        //验证手机号
        let isPhoneNumberNum = (rule, value, callback) => {
            if (!validPhone(value)) {
                return callback(new Error("请输入正确的手机号"));
            } else {
                callback();
            }
        };
        //验证身份证号
        let isidentityCardNumberNum = (rule, value, callback) => {
            if (!valididentityCard(value)) {
                return callback(new Error("请输入正确的身份证号"));
            } else {
                callback();
            }
        };
        return {
	        stationName:[],
	        trateName:[],
            // 筛选项
            filters:{
	            trateName:undefined,
	            stationName:undefined,
                keyWord:'',
                unitName:'',
                selectBatch: "",
                isPay:null,
                labelTy:'',
                dataSource:'',
                IsAllowExamination:'',
                isEnable: true,
                trainingInstitutions: "",
            },
            options:[
                {label:'无需缴费',value:0},
                {label:'已缴费',value:1},
                {label:'未缴费',value:2},
            ],
            IsAllowExaminationArr:[
                {label: '是',value: 1},
                {label: '否',value: 0}
            ],
            isEnableArr:[
                {label: '是',value: true},
                {label: '否',value: false}
            ],
            trainingInstitutionsArr: [],
            // 分页
            pages:{
                pageIndex:1,
                pageSize:20,
                dataCount:0
            },
            // 表格数据
            tableData:[],
            buttonList:[],
            listLoading:false,
            // 新增
            isAddOrEdit: true,
            addFormDialog: false,
            addLoading: false,
            imgsizePass: true,
            uploadImagesUrl: '',
            BackgroundWebImgUrl: '',
            dialogForm: {
                name: '',
                phone: '',
                userCard: '',
                userSex: '',
                unitName: '',
                unitAddress: '-',
                postType: '',
                industryType: '',
                labelType: '',
                stydyOrganization: '',
            },
            imageUrlDataStamp: '',
            dialogFormRules:{
                name:[{required:true,message:'请输入姓名',trigger:'blur'}],
                phone:[
                    {required:true,message:'请输入手机号',trigger:'blur'},
                    { validator: isPhoneNumberNum },
                ],
                userCard:[
                    {required:true,message:'请输入身份证号',trigger:'blur'},
                    { validator: isidentityCardNumberNum },
                ],
                unitName:[{required:true,message:'请输入单位名称',trigger:'blur'}],
                postType:[{required:true,message:'请选择岗位分类',trigger:'blur'}],
                industryType:[{required:true,message:'请选择行业分类',trigger:'blur'}],
                stydyOrganization:[{required:true,message:'请选择培训机构',trigger:'blur'}],
            },
            fileList:[],
            SocialUnitList:[],
            sexArr: [
                {label:'男',value:1},
                {label:'女',value:0}
            ],
            postTypeArr: [],
            industryTypeArr: [],
            labelTypeArr: [
                {label:'广东信用',value: "广东信用"},
                {label:'其他',value: "其他"}
            ],
            dataSourceArr: [
                {label:'Android',value:"Android"},
                {label:'IOS',value:"IOS"},
                {label:'PC',value:"PC"},
                // {label:'MAC',value:"Android"},
            ],
            stydyOrganizationArr: [],
            dialogImageUrl: '',
            dialogVisible: false,
            disabled: false,
            isShowOrg: false,
            isTradeSelect: false,
            tradePlaceholder: "请选择行业分类",
            // 导入
            importDialogVisible:false,
            actions:'',
            // 生成批次
            generateBatchDialog: false,
            generateBatchLoading: false,
            selectionData: [],
            allSelect: [],
            generateBatchForm: {
                batchName: "",
            },
            generateBatchFormRules:{
                batchName:[{required:true,message:'请输入批次名称',trigger:'blur'}],
            },
            // 加入批次
            addBatchDialog: false,
            addBatchLoading: false,
            bathArr: [],
            addBathIndex: 1,
            selectBatchRadio: "",
            // 申请组织考试
            sendMessageDialog: false,
            sendMessageLoading: false,
            sendMessageForm: {
                examTimer: '',
                examAddress: '',
            },
            sendMessageFormRules: {
                examTimer:[{required:true,message:'请选择考试时间',trigger:'blur'}],
                examAddress:[{required:true,message:'请输入考试地点',trigger:'blur'}],
            },
            DateTime: "DateTime",
            Address: "Address",
            // 查看学习记录
            examRecodeDialog: false,
            activeName: 'first',
            studyData: '',
            // 视频学习记录
            studyTableData: [],
            studyTableLoading: false,
            // 视频练习记录
            practiseTableData: [],
            practiseTableLoading: false,
            // 视频考试记录
            examTableData: [],
            examTableLoading: false,
            // 分页
            studyRecode:{
                // 学习
                studyIndex:1,
                studySize:20,
                studyCount:0,
                // 练习
                practiseIndex:1,
                practiseSize:20,
                practiseCount:0,
                // 考试
                examIndex:1,
                examSize:20,
                examCount:0,
            },
            // 打卡记录
            clockPhotoDialog: false,
            clockPhotoData: [],
            clockPhotoLoading: false,
            studyTimer: 0,
	        studyTimerlong: 0,
        }
    },
    methods:{
				stationList(){
					let params = {
						dataType:1,//数据类型:0:行业 1：岗位
						isEnable:true,//是否启用
						name:undefined,//名称
						projectId:parseInt(window.localStorage.getItem('projectId'))
					}
					getDataDictionaryList(params).then(res => {
						if (res.data.Success){
							console.log(res.data.Response)
							this.stationName = res.data.Response
						}
					})
				},
		    trateList(){
			    let params = {
				    dataType:0,//数据类型:0:行业 1：岗位
				    isEnable:true,//是否启用
				    name:undefined,//名称
				    projectId:parseInt(window.localStorage.getItem('projectId'))
			    }
			    getDataDictionaryList(params).then(res => {
				    if (res.data.Success){
					    this.trateName = res.data.Response
					    console.log(res.data.Response)
				    }
			    })
		    },
        // 列表样式
        rowClass(){
            return 'text-align:center;'
        },
        headerClass(){
            return 'text-align:center;'
        },
        // 筛选项清空重新获取数据
        handleClear(){
            this.getData()
        },
        // 分页处理
        handleSizeChange(value){
            this.pages.pageSize = value
            this.getData()
        },
        handleCurrentChange(value){
            this.pages.pageIndex = value
            this.getData()
        },
        // 查询
        getExamEnrollInfoListPage(){
            this.pages.pageIndex = 1
            this.getData()
        },
        // 获取表格数据
        getData(){
            var params = {
                keyWord:this.filters.keyWord ? this.filters.keyWord : null,
                unitName:this.filters.unitName ? this.filters.unitName : null,
                agencyInfoId:this.filters.trainingInstitutions ? this.filters.trainingInstitutions : null,
                // ExamBatchPopulationID: this.filters.selectBatch ? this.filters.selectBatch : null,
                // IsPay:this.filters.isPay ? this.filters.isPay : null,
                sourceType:this.filters.dataSource ? this.filters.dataSource : null,
                isEnable:this.filters.isEnable,
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize,
	              stationId:this.filters.stationName ? this.filters.stationName : null,
	              tradeId:this.filters.trateName ? this.filters.trateName : null
            }
            if(this.filters.IsAllowExamination === 1 || this.filters.IsAllowExamination === 0) {
                params.isExemptTrain = this.filters.IsAllowExamination
            }
            if(this.filters.labelTy) {
                params.remarks = this.filters.labelTy
            }
            this.listLoading = true
            let userinfo = JSON.parse(window.localStorage.user ? window.localStorage.user : null)
            if(userinfo && userinfo.IsAgency == false) {
                // getExamEnrollInfoListPage(params).then(res => {
                getStudentExamEnrollListPage(params).then(res => {
                    if(res.data.Success){
                        this.listLoading = false
                        this.tableData = res.data.Response.Data
                        this.pages.dataCount = res.data.Response.DataCount
                    }else{
                        this.listLoading = false
                        this.$message({
                            message:res.data.Message,
                            type:'error'
                        })
                    }
                })
            } else {
                getExamEnrollInfoListPageNew(params).then(res => {
                    if(res.data.Success){
                        this.listLoading = false
                        this.tableData = res.data.Response.Data
                        this.pages.dataCount = res.data.Response.DataCount
                    }else{
                        this.listLoading = false
                        this.$message({
                            message:res.data.Message,
                            type:'error'
                        })
                    }
                })
            }
        },
        // 新增
        handleAdd() {
            this.addFormDialog = true
            this.isAddOrEdit = true
            this.addLoading = false
            this.dialogForm = {
                name: '',
                phone: '',
                userCard: '',
                userSex: '',
                unitName: '',
                stydyOrganization: '',
                unitAddress: '-',
                postType: '',
                industryType: '',
                labelType: '',
            }
        },
        // 导入
        handleImport() {
            this.importDialogVisible = true
            this.actions = importStudentExamEnrollInfoUrl
        },
        // 文件上传失败
        submitFileError(err,file,fileList){
            this.$message.error('文件上传失败!')
            this.$refs.upload.clearFiles() 
        },
        // 文件上传成功
        submitFileSuccess(res,file,fileList) {
            if(res.Success){
                this.$message({
                    message:'文件上传成功！',
                    type:'success'
                })
                this.getData();
                this.importDialogVisible = false
                this.$refs.upload.clearFiles() 
            }else{
                this.$message({
                    message:res.Message,
                    type:'error'
                })
                this.$refs.upload.clearFiles() 
            }
        },
        // 文件上传前限制
        beforeUploadFile(file) {
            const isLt2M = file.size / 1024 / 1024 > 2;
            var type = file.name.substring(file.name.lastIndexOf(".")+1);
            if (type == 'jpg' || type == 'png') {
                this.$message.error('只能上传文件!');
                return false;
            }
            if (!isLt2M === false) {
                this.$message.error('上传文件大小不能超过 2MB!');
                return false;
            }
        },
        submitFileMore(files) {
            this.$message({
                message:'只能上传一个文件！',
                type:'warning'
            })
        },
        // 导出
        handleExport() {
            // var params = {
            //     keyWord:this.filters.keyWord ? this.filters.keyWord : '',
            //     examBatchPopulationID: this.filters.selectBatch,
            //     isPay:this.filters.isPay ? this.filters.isPay : '',
            //     isExemptTrain:this.filters.IsAllowExamination,
            //     sourceType:this.filters.dataSource,
            //     isEnable:this.filters.isEnable ? this.filters.isEnable : null,
            //     topCount:this.pages.pageSize,
            // }
            var params = {
                keyWord:this.filters.keyWord ? this.filters.keyWord : null,
                unitName:this.filters.unitName ? this.filters.unitName : null,
                examBatchPopulationID: this.filters.selectBatch ? this.filters.selectBatch : null,
                isPay:this.filters.isPay ? this.filters.isPay : null,
                sourceType:this.filters.dataSource ? this.filters.dataSource : null,
                isEnable:this.filters.isEnable,
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize,
            }
            if(this.filters.IsAllowExamination === 1 || this.filters.IsAllowExamination === 0) {
                params.isExemptTrain = this.filters.IsAllowExamination
            }
            if(this.filters.labelTy) {
                params.remarks = this.filters.labelTy
            }
            exportExamEnrollInfo(params).then(res => {
                if(res.data.Status == 200){
                    window.open(res.data.Response)

                    // const aLink = document.createElement("a");
                    // let blob = new Blob([res.data.Response], {type: "application/vnd.ms-excel"})
                    // aLink.href = URL.createObjectURL(blob)
                    // aLink.setAttribute('download', '考生信息' + '.xlsx') //导出文件赋命名+格式
                    // aLink.click()
                }else{
                    this.$message.error(result.Message)
                }
            })
        },
        // 生成批次
        generateBatch() {
            this.selectionData = this.$refs.multipleTable.selection
            if(this.selectionData.length == 0) {
                this.$message.warning("请选择需要生成批次的数据！")
            } else {
                this.generateBatchLoading = false
                this.generateBatchDialog = true
                this.generateBatchForm.batchName = ""
            }
        },
        generateBatchSubmit() {
            this.$refs['generateBatchFormRef'].validate(valid => {
                if(valid){
                    var ids = []
                    this.selectionData.forEach(item => {
                        ids.push(item.ID)
                    });
                    var params = {
                        ExamEnrollInfoIds: ids,
                        Name: this.generateBatchForm.batchName,
                    }
                    this.generateBatchLoading = true
                    generateBatch(params).then((res) => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.generateBatchDialog = false
                        } else {
                            this.$message.error(result.Message)
                        }
                        this.generateBatchLoading = false
                    })
                }
            })
        },
        // 加入批次
        addBatch() {
            this.selectionData = this.$refs.multipleTable.selection
            if(this.selectionData.length == 0) {
                this.$message.warning("请选择需要加入批次的数据！")
            } else {
                if(this.bathArr.length > 0) {
                    this.addBatchDialog = true
                    this.addBatchLoading = false
                    this.selectBatchRadio = ""
                } else {
                    // var params = {
                    //     pageIndex: this.addBathIndex,
                    //     pageSize: 20,
                    // }
                    // getExamBatchPopulationList(params).then((res) => {
                    //     var result = res.data
                    //     if(result.Success) {
                    //         this.bathArr = result.Response.Data
                    //         this.addBatchDialog = true
                    //         this.addBatchLoading = false
                    //         this.selectBatchRadio = ""
                    //     } else {
                    //         this.$message.error(result.Message)
                    //     }
                    // })
                }
            }
        },
        addBathArrChange(val) {
            this.addBathIndex = val
            // this.getBatchFun()
        },
        // 选中表格行
        handleSelectChange(allRow){
            this.allSelect = allRow
        },
        // 获取批次
        getBatchFun() {
            var params = {
                pageIndex: this.addBathIndex,
                pageSize: 20,
            }
            getExamBatchPopulationList(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    this.bathArr = result.Response.Data
                }
            })
        },
        addBatchSubmit() {
            if(this.selectBatchRadio) {
                var ids = []
                this.selectionData.forEach(item => {
                    ids.push(item.ID)
                });
                var params = {
                    ExamEnrollInfoIds: ids,
                    ExamBatchPopulationID: this.selectBatchRadio,
                }
                this.addBatchLoading = true
                addBatch(params).then((res) => {
                    var result = res.data
                    if(result.Success) {
                        this.$message.success(result.Message)
                        this.addBatchDialog = false
                    } else {
                        this.$message.error(result.Message)
                    }
                    this.addBatchLoading = false
                })
            } else {
                this.$message.warning("请选择要加入的批次！")
            }
        },
        // 免培考试
        updateIsExemptTrain() {
            this.selectionData = this.$refs.multipleTable.selection
            if(this.selectionData.length == 0) {
                this.$message.warning("请选择免培考试的人！")
            } else {
                this.$confirm(`已选择` + this.selectionData.length + `人，是否免培考试`,"提示",{
                }).then(() => {
                    var ids = []
                    this.selectionData.forEach(item => {
                        ids.push(item.ID)
                    });
                    var params = {
                        Ids: ids,
                    }
                    updateIsExemptTrain(params).then((res) => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.getData()
                        } else {
                            this.$message.error(result.Message)
                        }
                    })
                })
                .catch((err) => {

                })
            }
        },
        // 申请组织考试
        sendMessage() {
            this.selectionData = this.$refs.multipleTable.selection
            if(this.selectionData.length == 0) {
                this.$message.warning("请选择需要申请组织考试的人群！")
            } else {
                this.sendMessageDialog = true
                this.sendMessageLoading = false
                this.sendMessageForm = {
                    examTimer: '',
                    examAddress: '',
                }
            }
        },
        sendMessageSubmit() {
            this.$refs['sendMessageFormRef'].validate(valid => {
                if(valid){
                    this.$message.warning("禁止发送操作，请联系管理员！")
                    // this.$confirm(`确定对已选中【` + this.selectionData.length + `】人群申请组织考试`,"提示",{
                    // }).then(() => {
                    //     var ids = []
                    //     this.selectionData.forEach(item => {
                    //         ids.push(item.StudentExamineeAccountID)
                    //     });
                    //     var params = {
                    //         ExamTime: this.formatCreateTime(this.sendMessageForm.examTimer),
                    //         ExamPlace: this.sendMessageForm.examAddress,
                    //         StudentExamineeAccountIDs: ids,
                    //     }
                    //     this.sendMessageLoading = true
                    //     candidateSendsSMS(params).then((res) => {
                    //         var result = res.data
                    //         if(result.Success) {
                    //             this.sendMessageDialog = false
                    //             this.$message.success(result.Message)
                    //             this.getData();
                    //         } else {
                    //             this.$message.warning(result.Message)
                    //         }
                    //         this.sendMessageLoading = false
                    //     })
                    // })
                    // .catch((err) => {

                    // })
                }
            })
        },
        // 提交
        addSubmit() {
            var params = {
                Name: this.dialogForm.name,
                PhoneNumber: this.dialogForm.phone,
                CardNo: this.dialogForm.userCard,
                Gender: this.dialogForm.userSex,
                // unitName: this.dialogForm.unitName,
                StationCode: this.dialogForm.postType,
                TradeCode: this.dialogForm.industryType,
                AgencyID: this.dialogForm.stydyOrganization,
                HeadPhotoUrl: this.imageUrlDataStamp.webupurl,
                IsVerification: true,
            }
            this.SocialUnitList.forEach(item => {
                if(item.ID == this.dialogForm.unitName){
                    params.SocialUnitID = item.ID
                }
            })
            this.$refs['dialogFormRef'].validate(valid => {
                if(valid){
                    this.addLoading = true
                    submitStudentInformationByManger(params).then(res => {
                        var result = res.data
                        if(result.Success) {
                            this.$message.success(result.Message)
                            this.getData()
                            this.addFormDialog = false
                        } else {
                            this.$message.error(result.Message)
                        }
                        this.addLoading = false
                    })
                }
            })
        },
        // 编辑dialog关闭
        dialogCloseFun() {
            this.$refs['uploadRef'].clearFiles()
            var imageHtml = document.getElementsByClassName('el-upload--picture-card')[0]
            imageHtml.style.cssText = 'display: block;'
        },
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 图片上传之前函数
        beforeUpload(file) {
            var _this = this
            const isLt2M = file.size / 1024 / 1024 > 0.81;
            const isLt30K = file.size / 1024 / 1024 < 0.039;
            var type = file.name.substring(file.name.lastIndexOf(".")+1);
            if (type == 'jpg' || type == 'png') {} else {
                this.$message.error('上传图片只能是 JPG/PNG 格式!');
                return false;
            }
            if (!isLt2M === false) {
                this.$message.error('上传图片大小不能超过 800KB!');
                return false
            }
            if (!isLt30K === false) {
                this.$message.error('上传图片须大于或等于40KB!');
                return false
            }
            const isSize = new Promise(function(resolve,reject) {
                let width = 413
                let height = 626
                let _URL = window.URL || window.webkitURL
                let img = new Image()
                img.onload = function() {
                    let valid = img.width >= width && img.height >= height
                    if(!valid){
                        _this.imgsizePass = false
                        return false
                    }else{
                        _this.imgsizePass = true
                    }
                }
                img.src = _URL.createObjectURL(file)
            })
        },
        // 图片上传成功时的函数
        handle_success(res) {
            if(this.imgsizePass) {
                if(res.Success) {
                    this.$message.success('图片上传成功')
                    this.imageUrlDataStamp = res.Response
                    this.BackgroundWebImgUrl = res.Response.weburl
                    var imageHtml = document.getElementsByClassName('el-upload--picture-card')[0]
                    imageHtml.style.cssText = 'display: none;'
                }else{
                    this.$message.error(res.Message)
                    var imageHtmlRemo = document.getElementsByClassName('el-upload-list el-upload-list--picture-card')[0]
                    var pObjs = imageHtmlRemo.childNodes;
                    for (var i = pObjs.length - 1; i >= 0; i--) {
                        imageHtmlRemo.removeChild(pObjs[i]);
                    }
                }
            }else{
                var imageHtmlRemo = document.getElementsByClassName('el-upload-list el-upload-list--picture-card')[0]
                var pObjs = imageHtmlRemo.childNodes;
                for (var i = pObjs.length - 1; i >= 0; i--) {
                    imageHtmlRemo.removeChild(pObjs[i]);
                }
                this.$message.error('上传图片尺寸须大于413*626像素!')
            }
		},
        // 图片删除函数
        handleRemove(file, fileList) {
            this.imageUrlDataStamp = ''
            this.BackgroundWebImgUrl = ''
            this.$message.success('图片删除成功')
            var imageHtml = document.getElementsByClassName('el-upload--picture-card')[0]
            imageHtml.style.cssText = 'display: block;'
            var imageHtmlRemo = document.getElementsByClassName('el-upload-list__item is-success')[0]
            imageHtmlRemo.parentNode.removeChild(imageHtmlRemo)
        },
        // 获取数据字典岗位、行业
        getDataDictionaryList() {
            var params = {}
            this.postTypeArr = []
            this.industryTypeArr = []
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    result.Response.forEach(item => {
                        if(item.DataType == 0) {
                            this.industryTypeArr.push(item)
                        }
                        if(item.DataType == 1) {
                            this.postTypeArr.push(item)
                        }
                    });
                }
            })
        },
        searchFun(val) {
            this.getSocialUnitFun(val)
        },
        // 获取社会单位
        getSocialUnitFun(val) {
            var params = {
                socialUnitName: val
            }
            getSocialUnitListPage(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.SocialUnitList = result.Response.Data
                }
            })
        },
        searchOrgFun(val) {
            this.getOrganizationFun(val)
        },
        // 获取培训机构
        getOrganizationFun(val) {
            var params = {
                pageIndex: 1,
                pageSize: 10,
                status: 1,
                name: val
            }
            getAgencyInfoListPage(params).then((res) => {
                var result = res.data
                if(result.Success){
                    this.stydyOrganizationArr = result.Response.Data
                }
            })
        },
        // 学习记录
        viewStudentMsgDetail(val) {
            this.examRecodeDialog = true
            this.studyData = val
            this.studyTimer = 0
            this.activeName = "first"
            this.getStudyTimerFun()
            this.getStudyRecordFun(val.StudentExamineeAccountID)
        },
        handleClick(tab, event) {
            var tabIndex = tab.index
            this.studyRecode = {
                // 学习
                studyIndex:1,
                studySize:20,
                studyCount:0,
                // 练习
                practiseIndex:1,
                practiseSize:20,
                practiseCount:0,
                // 考试
                examIndex:1,
                examSize:20,
                examCount:0,
            }
            if(tabIndex == 0) {
                this.getStudyRecordFun(this.studyData.StudentExamineeAccountID)
            } else if(tabIndex == 1) {
                this.getPractiseRecordFun(this.studyData.StudentExamineeAccountID)
            } else {
                this.getExamRecordFun(this.studyData.StudentExamineeAccountID)
            }
        },
        // 获取学习记录列表
        getStudyRecordFun(val) {
            var params = {
                enrollId: this.studyData.ID,
                pageIndex: this.studyRecode.studyIndex,
                pageSize: this.studyRecode.studySize,
            }
            this.studyTableLoading = true
            getStudentExamineeAccountStudyListPage(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    if(result.Response) {
                        this.studyTableData = result.Response.Data
                        this.studyRecode.studyCount = result.Response.DataCount
                    }
                }
                this.studyTableLoading = false
            })
        },
        // 获取学习记录总时长
        getStudyTimerFun(val) {
            var params = {
                enrollId: this.studyData.ID,
            }
            getLearnRecordByEnroll(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    this.studyTimer = +result.Response.QualifiedLineDuration
                    this.studyTimerlong = result.Response?+result.Response.OnlineDuration +( +result.Response.OffLineDuration) + (+result.Response.HandsOnDuration):0
                }
            })
        },
        // 学习记录分页
        studySizeChange(value){
            this.studyRecode.studySize = value
            this.getStudyRecordFun()
        },
        studyCurrentChange(value){
            this.studyRecode.studyIndex = value
            this.getStudyRecordFun()
        },
        // 查看学习记录拍照
        viewStudyPhoto(val) {
            this.clockPhotoDialog = true,
            this.clockPhotoLoading = false,
            this.clockPhotoData = val.ClockInPics
        },
        // 获取练习记录
        getPractiseRecordFun(val) {
            var params = {
                // studentAccountId: this.studyData.StudentExamineeAccountID,
                enrollId: this.studyData.ID,
                pageIndex: this.studyRecode.practiseIndex,
                pageSize: this.studyRecode.practiseSize,
            }
            this.practiseTableLoading = true
            getStudentExamineeAccountTrainListPage(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    if(result.Response) {
                        this.practiseTableData = result.Response.Data
                        this.studyRecode.practiseCount = result.Response.DataCount
                    }
                }
                this.practiseTableLoading = false
            })
        },
        // 练习记录分页
        practiseSizeChange(value){
            this.studyRecode.practiseCount = value
            this.getPractiseRecordFun()
        },
        practiseCurrentChange(value){
            this.studyRecode.practiseIndex = value
            this.getPractiseRecordFun()
        },
        // 获取考试记录
        getExamRecordFun(val) {
            var params = {
                // studentAccountId: this.studyData.StudentExamineeAccountID,
                enrollId: this.studyData.ID,
                pageIndex: this.studyRecode.examIndex,
                pageSize: this.studyRecode.examSize,
            }
            this.examTableLoading = true
            getStudentExamineeAccountExamListPage(params).then((res) => {
                var result = res.data
                if(result.Success) {
                    if(result.Response) {
                        this.examTableData = result.Response.Data
                        this.studyRecode.examCount = result.Response.DataCount
                    }
                }
                this.examTableLoading = false
            })
        },
        // 考试记录分页
        examSizeChange(value){
            this.studyRecode.examSize = value
            this.getExamRecordFun()
        },
        examCurrentChange(value){
            this.studyRecode.examIndex = value
            this.getExamRecordFun()
        },
        // 身份证input失焦触发
        onBlur(val) {
            if(this.strlen(val) == 18){
                this.dialogForm.userSex = util.getAnalysisIdCard(val,2)
            }
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible45 = true;
        },
        getToken() {
            return localStorage.getItem("Token")
        },
        //时间格式化
        formatCreateTime: function(timer) {
            return !timer || timer == "" ? "" : util.formatDate.format(new Date(timer), "yyyy-MM-dd hh:mm:ss");
        },
        strlen(str){
            var len = 0;
            for (var i=0; i<str.length; i++) { 
            var c = str.charCodeAt(i); 
            //单字节加1 
            if ((c >= 0x0001 && c <= 0x007e) || (0xff60<=c && c<=0xff9f)) { 
            len++; 
            } 
            else { 
                len+=2; 
            } 
            } 
            return len;
        },
        // 秒转化时分秒
        second(value) {
            var theTime = parseInt(value);// 秒
            var middle= 0;// 分
            var hour= 0;// 小时
            if(theTime >= 60) {
                middle= parseInt(theTime/60);
                theTime = parseInt(theTime%60);
                if(middle>= 60) {
                    hour= parseInt(middle/60);
                    middle= parseInt(middle%60);
                }
            }
            var result = ""+parseInt(theTime)+"";
            if(middle > 0) {
                result = ""+parseInt(middle)+":"+result;
            }
            if(hour> 0) {
                result = ""+parseInt(hour)+":"+result;
            }
            return result;
        }
    },
    computed: {
        headers() {
            return{
                "Authorization": 'Bearer ' + this.getToken() // 直接从本地获取token就行
            }
        }
    },
    watch: {
        'dialogForm.unitName': function(val) {
            this.SocialUnitList.forEach(item => {
                if(item.ID == val){
                    this.dialogForm.unitAddress = item.Address
                }
            })
        },
        'sendMessageForm.examTimer': function(val) {
            if(val) {
                this.DateTime = this.formatCreateTime(val)
            } else {
                this.DateTime = "DateTime"
            }
        },
        'sendMessageForm.examAddress': function(val) {
            if(val) {
                this.Address = val
            } else {
                this.Address = "Address"
            }
        },
        'dialogForm.postType': function(val) {
            this.postTypeArr.forEach(item => {
                if(val == item.Code) {
                    if(item.IsRelation) {
                        this.isTradeSelect = false
                        this.tradePlaceholder = "请选择行业分类"
                    } else {
                        this.isTradeSelect = true
                        this.tradePlaceholder = "您选择的岗位不需要选择行业"
                        this.dialogForm.industryType = ""
                    }
                }
            })
        },
    },
    created(){
				this.trateList()
	      this.stationList()
        this.uploadImagesUrl = api.uploadImagesUrl
        var batchId = this.$route.query.batchId
        if(batchId) {
            this.filters.selectBatch = Number(batchId)
        }
        let userinfo = JSON.parse(window.localStorage.user ? window.localStorage.user : null)
        if(userinfo && userinfo.IsAgency == false) {
            this.isShowOrg = true
        }
    },
    mounted(){
        this.getData()
        this.getDataDictionaryList()
        // this.getSocialUnitFun()
        // this.getBatchFun()
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    }
}
</script>

<style lang="stylus" scoped>
    // .pageBar{
    //     margin-top:15px!important;
    // }
    .pageBar{
	    display: flex;
	    align-items: center;
	    font-size: 14px;
	    height :70px;
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }
    .avatar {
        width: 100%;
        height: 100%;
        display: block;
    }
    .social {
        width 100%;
    }
    .messageTitle{
        color: #999;
        font-size: 16px;
    }
    .messageContent {
        font-size: 16px;
        margin-top: 10px;
    }
    .pageBarContainer {
        display: flex;
        align-items: center;
        font-size: 14px;
    }
    .selectNumCla {
        color: #409EFF;
        font-weight: bold;
        margin: 0 5px;
        font-size: 16px;
    }
</style>